import ApiService from './api.service'

export default class NutritionService {

    getProductsSmall() {
		//return axios.get('assets/demo/data/products-small.json').then(res => res.data.data);
	}

	async getNutritions(_params) {
        const response = await ApiService.get('api/nutritions', _params)
        return response.data
    }

	async newNutrition(nutrition) {
        const response = await ApiService.post('api/nutritions', nutrition)
        return response.data.id
    }

	async updateNutrition(nutrition) {
        const response = await ApiService.put('api/nutritions/'+nutrition.id, nutrition)
        return response.data.id
    }

	async deleteNutrition(id) {
        const response = await ApiService.delete('api/nutritions/'+id)
        return response
    }

}
