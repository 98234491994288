<template>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card" id="maincard">
				<Toast/>
				<Toolbar class="p-mb-0">
					<template #left>
						<Button label="Új" icon="pi pi-plus" class="p-button-success p-mr-2" @click="openNew" />
						<!--Button label="Töröl" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectedNutritions || !selectedNutritions.length" /-->
						<!--Button label="Teszt" icon="pi pi-plus" class="p-button-info p-mr-2" @click="teszt" style="margin-left: 7px" /-->
                        <!--Dropdown v-model="nutritionClass" :options="codesNutritionClass" optionLabel="value" optionValue="code" placeholder="Select a Class" /-->
					</template>

					<!--template #right>
						<FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import" class="p-mr-2 p-d-inline-block" />
						<Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)"  />
					</template-->
				</Toolbar>


                <!-- Táblázat -->

				<DataTable ref="dt" :value="nutritions" v-model:expandedRows="expandedRows" dataKey="id" :paginator="true" :rows="25" :filters="filters"
                            sortField="nutrname" :sortOrder="1" :loading="loading" @filter="onFilter($event)" @row-click="expandRow"
                            :lazy="true" :totalRecords="totalRecords" @page="onPage($event)" @sort="onSort($event)"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25,100]"
							currentPageReportTemplate="{first} - {last} / {totalRecords}" class="p-datatable-customers p-datatable-sm p-datatable-hoverable-rows ">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Karbantartás: Tápszerek</h5>
							<span class="p-input-icon-left">
                                <i :class="(loadingSearch) ? ['pi', 'pi-spin', 'pi-spinner'] : ['pi', 'pi-search']"/>
                                <InputText v-model="searchGlobal" placeholder="Keresés..." />
                            </span>
						</div>
					</template>

					<Column :expander="true" headerStyle="width: 3rem" />
					<Column field="nutrname" header="Megnevezés" headerStyle="width: 20rem" sortable></Column>
					<Column field="custtype" header="Típus" headerStyle="width: 6rem" sortable></Column>
					<Column field="package" header="Csomag" sortable></Column>
					<Column field="kcal_kg" header="kcal / kg" sortable></Column>
					<Column field="maxcomission" header="Max. mennyiség" sortable></Column>
					<Column>
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editNutrition(slotProps.data.id)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDeleteNutrition(slotProps.data)" />
						</template>
					</Column>
					<template #expansion="slotProps">
                        <Card>
                        <template #content>
                            <div class="p-grid">
                                <div class="p-col" style="margin-left: 2rem">
                                    <p class="p-d-inline-block" style="margin-right: 1rem">Megjegyzés:</p>
                                    <p class="p-d-inline" style="white-space: pre-wrap" v-html="slotProps.data.note"></p>
                                </div>
                                <!--div class="p-col">Város: {{ slotProps.data.package }}</div-->
                            </div>
                        </template>                            
                        </Card>
                    </template>
                </DataTable>


                <!-- Szerkesztő ablak -->

				<Dialog id="dialog" v-model:visible="nutritionDialog" :style="{width: '450px', 'margin-top': '16px' }" 
                    header="Tápszer" :modal="true" class="p-fluid"
                    @keydown.enter.ctrl="saveNutrition()" @keyup.esc="hideDialog()">
					<span class="p-field p-float-label">
                        <SelectButton v-model="nutrition.custtype" 
                            required="true" :v="v$.nutrition.custtype" :class="{'p-invalid': v$.nutrition.custtype.$invalid }" @blur="v$.nutrition.custtype.$touch"
                            :options="[{code: 'S', name: 'Szondatáplált'}, {code: 'M', name: 'Metabolik'}]" optionLabel="name" optionValue="code"
                        />
						<small class="p-invalid" v-if="vCheckRequire('custtype')">{{ errorMessages.required }}</small>
					</span>
					<span class="p-field p-float-label">
						<InputText id="nutrname" v-model.trim.lazy="nutrition.nutrname" :v="v$.nutrition.fullname" @blur="v$.nutrition.nutrname.$touch"
                            required="true" :class="{'p-invalid': v$.nutrition.nutrname.$invalid}" autocomplete="new-hosp-nutrname"
                        />
						<label for="fullname">Megnevezés</label>
						<small class="p-invalid" v-if="vCheckRequire('nutrname')">{{ errorMessages.required }}</small>
					</span>
					<span class="p-field p-float-label">
						<InputText id="package" v-model.trim.lazy="nutrition.package" :v="v$.nutrition.package" @blur="v$.nutrition.package.$touch"
                            required="true" :class="{'p-invalid': v$.nutrition.package.$invalid}" autocomplete="new-hosp-package"
                        />
						<label for="package">Csomag</label>
						<small class="p-invalid" v-if="vCheckRequire('package')">{{ errorMessages.required }}</small>
					</span>
					<span class="p-field p-float-label">
						<InputNumber id="kcal_kg" v-model.lazy="nutrition.kcal_kg" :v="v$.nutrition.kcal_kg" @blur="v$.nutrition.kcal_kg.$touch"
                            required="true" :class="{'p-invalid': v$.nutrition.kcal_kg.$invalid}" autocomplete="new-hosp-kcal_kg"
                            :max="10000"
                        />
						<label for="kcal_kg">kcal / kg</label>
						<small class="p-invalid" v-if="vCheckRequire('kcal_kg')">{{ errorMessages.required }}</small>
					</span>
					<span class="p-field p-float-label">
						<InputNumber id="maxcomission" v-model.lazy="nutrition.maxcomission" :v="v$.nutrition.maxcomission" @blur="v$.nutrition.maxcomission.$touch"
                            required="true" :class="{'p-invalid': v$.nutrition.maxcomission.$invalid}" autocomplete="new-hosp-maxcomission"
                            :max="10000"
                        />
						<label for="maxcomission">Max. mennyiség</label>
						<small class="p-invalid" v-if="vCheckRequire('maxcomission')">{{ errorMessages.required }}</small>
					</span>
					<span class="p-field p-float-label">
						<Textarea id="description" v-model="nutrition.note" rows="3" cols="20" style="resize: vertical"
                        />
						<label for="description">Megjegyzés</label>
					</span>

					<template #footer>
                        <small class="p-error p-d-block" v-if="v$.$error">{{ errorMessages.formError }}</small>
						<Button label="Mégse" icon="pi pi-times" class="p-button-text" 
                            v-tooltip.bottom="'ESC'" tabindex="-1"
                            @click="hideDialog"/>
						<Button label="Mentés" :icon="(submitted) ? 'pi pi-spin pi-spinner' : 'pi pi-check'" class="p-button-success" 
                            v-tooltip.bottom="'Ctrl+ENTER'" :disabled="v$.$error || submitted"
                            @click="saveNutrition"/>
					</template>
				</Dialog>


                <!-- Törlés kérdés felugró -->

				<Dialog v-model:visible="deleteNutritionDialog" :style="{width: '450px'}" header="Megerősítés" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="nutrition">Biztosan töröljük a tápanyagot: <b>{{nutrition.nutrname}}</b> ?</span>
					</div>
					<template #footer>
						<Button label="Mégse" icon="pi pi-times" class="p-button-text" @click="deleteNutritionDialog = false"/>
						<Button label="Törlés" :icon="(submitted) ? 'pi pi-spin pi-spinner' : 'pi pi-trash'" class="p-button-danger" 
                            @click="deleteNutrition"/>
					</template>
				</Dialog>

			</div>
		</div>
	</div>

</template>

<script>
//import { mapGetters, mapActions } from 'vuex'
import NutritionService from '@/service/backend/nutrition.service'
import useVuelidate from '@vuelidate/core'
import { required, errorMessages } from  '@/texts/errorMessages'

export default {
    setup: () => ({ v$: useVuelidate() }),
    validationConfig: {
        $lazy: true,
        $autoDirty: true
    },
	data() {
		return {
			nutritions: null,
			nutritionDialog: false,
			deleteNutritionDialog: false,
			deleteNutritionsDialog: false,
			nutrition: {},
			expandedRows: [],
			filters: {},
            submitted: false,
            searchGlobal: "",
            totalRecords: 0,
            lazyParams: {},
            loading: true,
            loadingSearch: false,
            searchInterval: null,
            errorMessages,

            //nutritionClass: {code: "1", value:"Házikórház"}, // Dropdown teszthez

		}
	},
    validations: {
        nutrition: {
            nutrname: { required },
            custtype: { required }, 
            package: { required },
            kcal_kg: { required }, 
            maxcomission: { required }
        }
    },
    nutritionService: null,
	created() {
		this.nutritionService = new NutritionService()
    },
    beforeMount() {
        //this.loadCodeStore('nutritionClass') // Abban az esetben, ha API-s a codeStore - de nem kell ezt tudnia, jöhetne
    },
	mounted() {
        this.lazyParams = {
            first: 0,
            page: 1,
            rows: this.$refs.dt.rows,
            sortField: this.$refs.dt.sortField,
            sortOrder: 1,
            filters: null
        };

        this.loadLazyData();
	},
	methods: {
/*        ...mapActions({
            getCodeStore: 'codeStore/loadCodeStore'
        }), */
        loadLazyData() {
            this.loading = true;

            delete this.lazyParams.originalEvent
            delete this.lazyParams.pageCount
            delete this.lazyParams.filterMatchModes
            this.lazyParams.filters = this.filters.global

            this.nutritionService.getNutritions(this.lazyParams)
            .then( result => {
                this.nutritions = result.data
                this.totalRecords = result.totalRecords
                this.loading = false
            })
        },
        onPage(event) {
            this.lazyParams = event
            this.lazyParams.page++
            this.loadLazyData()
        },
        onSort(event) {
            this.onPage(event)
        },
        onFilter() {
            this.loading = true
            this.lazyParams.page = 1
            this.loadLazyData()
            this.loadingSearch = false
        },
        vCheckRequire(field){
            return this.v$.nutrition[field].$dirty && !this.v$.nutrition[field].required
        },
        expandRow(e) {
            if(this.expandedRows.length === 0 || this.expandedRows[0].id !== e.data.id ){
                this.expandedRows = this.nutritions.filter(p => p.id == e.data.id);
            }else{
                this.expandedRows = []
            }
        },
        teszt() {
            this.$toast.add({severity:'success', summary: 'Teszt!', detail: 'Tesztelve!', life: 30000 })
        },
        openDialog(){
            this.nutritionDialog = true
            this.submitted = false
            this.$nextTick(() => { this.v$.$reset() })
        },
		openNew() {
            this.nutrition = {}
            this.openDialog()
		},
		hideDialog() {
			this.nutritionDialog = false
            this.submitted = false
		},
		async saveNutrition() {

            this.submitted = true;
            this.v$.nutrition.$touch()

            if (!this.v$.$invalid) {

                    try{
                        if (this.nutrition.id) {
                            this.nutrition.id = await this.nutritionService.updateNutrition(this.nutrition)
                            this.nutritions[this.findIndexById(this.nutrition.id)] = this.nutrition
                            this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Frissítve!', life: 3000})
                        }
                        else {
                            this.nutrition.id = await this.nutritionService.newNutrition(this.nutrition)
                            this.nutritions.unshift(this.nutrition)
                            this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Létrehozva!', life: 3000})
                        }
                        this.submitted = false
                        this.hideDialog()

                    }catch{
                        // TODO server oldali hiba kiírása?
                        this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Valami hiba történt', life: 3000})
                    }finally{
                        this.submitted = false
                    }

            }

            this.submitted = false

		},
		editNutrition(id) {
            this.nutrition = JSON.parse(JSON.stringify(this.nutritions.find( d => d.id === id)));
            this.openDialog()
		},
		confirmDeleteNutrition(nutrition) {
			this.nutrition = nutrition
			this.deleteNutritionDialog = true
		},
		async deleteNutrition() {
            this.submitted = true
            try{
                await this.nutritionService.deleteNutrition(this.nutrition.id)
                this.nutritions = this.nutritions.filter(val => val.id !== this.nutrition.id)
                this.submitted = false
                this.deleteNutritionDialog = false
                this.nutrition = {}
                this.$toast.add({severity:'success', summary: 'Siker', detail: 'Nutrition Deleted', life: 3000})
            }catch{
                this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Valami hiba történt', life: 3000})
                this.submitted = false
                return
            }
		},
		findIndexById(id) {
			let index = -1
			for (let i = 0; i < this.nutritions.length; i++) {
				if (this.nutritions[i].id === id) {
					index = i
					break
				}
			}

			return index;
		},
		exportCSV() {
			this.$refs.dt.exportCSV()
		},
		/*confirmDeleteSelected() {
			this.deleteNutritionsDialog = true;
		},
		deleteSelectedNutritions() {
			this.nutritions = this.nutritions.filter(val => !this.selectedNutritions.includes(val));
			this.deleteNutritionsDialog = false;
			this.selectedNutritions = null;
			this.$toast.add({severity:'success', summary: 'Siker', detail: 'Nutritions Deleted', life: 3000});
        },*/
        ctrlEntered() {
            //saveNutrition
            console.log('ctrlEntered()')
        }
    }, 
    watch: {
        searchGlobal(val) {
            clearTimeout(this.searchInterval)
            this.searchInterval = setTimeout(() => {
                this.loadingSearch = true
                setTimeout(() => { this.filters['global'] = val; this.onFilter(); }, 20)
            }, 600)
        }
    },
    computed: {
/*        ...mapGetters({
            codesNutritionClass: 'codeStore/nutritionClass'
        })  */
    }
}
</script>
<style>
.p-datatable-tbody {
    cursor: pointer;
}
.p-datatable-row-expansion {
    cursor: auto;
}
.p-card .p-card-content {
    padding: 0 0 0.2rem 0;
}
</style>

<style scoped lang="postcss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.nutrition-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .nutrition-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.nutrition-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

#maincard {
    padding-top: 0px;
}

@media screen and (max-width: 960px) {
	::deep() .p-datatable {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				border-bottom: 1px solid var(--surface-d);

				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
					}
				}
			}
		}
	}

	::deep() .p-toolbar {
		flex-wrap: wrap;

		.p-button {
			margin-bottom: .25rem;
		}
    }
    
}
</style>
